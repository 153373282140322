define("ember-composable-helpers/helpers/invoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invoke = invoke;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _EmberRSVP = Ember.RSVP,
      all = _EmberRSVP.all;

  function invoke(_ref) {
    var _ref2 = _toArray(_ref),
        methodName = _ref2[0],
        args = _ref2.slice(1);

    var obj = args.pop();

    if (Ember.isArray(obj)) {
      return function () {
        var promises = obj.map(function (item) {
          return Ember.tryInvoke(item, methodName, args);
        });
        return all(promises);
      };
    }

    return function () {
      return Ember.tryInvoke(obj, methodName, args);
    };
  }

  var _default = Ember.Helper.helper(invoke);

  _exports.default = _default;
});