define('ember-experiments/mixins/activate-experiments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    experiments: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var qp = transition.queryParams;
      var experiments = qp.experiments;

      if (experiments) {
        experiments = experiments.split(',').map(function (e) {
          return e.split('/');
        });
        experiments.forEach(function (e) {
          if (e.length === 2) {
            _this.get('experiments').enable(e[0], e[1]);
          }
        });
      }

      this._super.apply(this, arguments);
    }
  });
});