define("@dwind/widi-uikit/components/widi-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['widi-price'],
    currency: 'EUR',
    showCentsAlways: true,
    separatorRegExp: Ember.computed({
      get: function get() {
        return new RegExp(/\.|,/, 'g');
      }
    }),
    cleanAmount: Ember.computed('amount', {
      get: function get() {
        return this.amount ? this.amount.replace(',', '.') : '';
      }
    }).readOnly(),
    formattedPrice: Ember.computed('cleanAmount', 'currency', {
      get: function get() {
        return this.intl.formatNumber(this.cleanAmount, {
          style: 'currency',
          locale: 'it-it',
          currency: this.currency
        });
      }
    }).readOnly(),
    formattedAmount: Ember.computed('formattedPrice', {
      get: function get() {
        var _this$formattedPrice$ = this.formattedPrice.split(/\s/),
            _this$formattedPrice$2 = _slicedToArray(_this$formattedPrice$, 1),
            formattedAmount = _this$formattedPrice$2[0];

        return formattedAmount;
      }
    }).readOnly(),
    formattedCurrency: Ember.computed('formattedPrice', {
      get: function get() {
        var _this$formattedPrice$3 = this.formattedPrice.split(/\s/),
            _this$formattedPrice$4 = _slicedToArray(_this$formattedPrice$3, 2),
            formattedCurrency = _this$formattedPrice$4[1];

        return formattedCurrency;
      }
    }).readOnly(),
    units: Ember.computed('formattedPrice', 'separatorRegExp', {
      get: function get() {
        var _this$formattedPrice$5 = this.formattedPrice.slice().split(this.separatorRegExp),
            _this$formattedPrice$6 = _slicedToArray(_this$formattedPrice$5, 1),
            units = _this$formattedPrice$6[0];

        return parseInt(units, 10);
      }
    }).readOnly(),
    cents: Ember.computed('formattedPrice', 'separatorRegExp', {
      get: function get() {
        var _this$formattedPrice$7 = this.formattedPrice.slice().split(this.separatorRegExp),
            _this$formattedPrice$8 = _slicedToArray(_this$formattedPrice$7, 2),
            cents = _this$formattedPrice$8[1];

        return parseInt(cents, 10);
      }
    }).readOnly(),
    separator: Ember.computed('formattedPrice', 'separatorRegExp', {
      get: function get() {
        return (this.formattedPrice.match(this.separatorRegExp) || [',']).pop();
      }
    }).readOnly()
  });

  _exports.default = _default;
});